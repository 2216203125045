.App {
  /*text-align: center;*/
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.main-top-wrapper{
  padding-top: 64px;
}
.main-container {
  max-width: 1200px;
  margin: 0 auto;
}

.main-footer {
  display: block;
  width: 100%;
  background-color: #252525;
  color: #909090;
}
.footer-bottom {
  display: block;
  width: 100%;
  background-color: #181818;
  color: #909090;
  padding: 2px 10px 2px 10px;
}
.rpad10 {padding-right: 10px;}
.lpad10 {padding-left: 10px;}
.tpad5 {padding-top: 5px;}
.tpad10 {padding-top: 10px;}
.tpad20 {padding-top: 20px;}
.bpad10 {padding-bottom: 10px;}
.d-block {display: block;}
.font-bold {font-weight: bold;}
.uppercase {text-transform: uppercase;}
.font-strikeout {text-decoration: line-through}
.mat-price {font-size: 1.2em;}
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.home_slider {
    width: 100%;
    display: block;
    position: relative;
}

.carousel .slide {
  background: #fff;
}

.carousel .slide img {
    height: auto;
}

.banner-top-title {
  text-transform: uppercase;
  margin: 0;
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: 3;
  top: 12%;
  left: 0px;
}
.banner-top-subtitle {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  position: absolute;
  width: 100%;
  z-index: 3;
  top: 5%;
  left: 0px;
}
.banner-top-subtitle2 {
  color: #fff;
  position: absolute;
  width: 100%;
  top: 30%;
  left: 0;
  z-index: 3;
  text-align: center;
}
.banner-bg-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../img/bgbox.png') repeat;
  z-index: 2;
}
.banner-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130px;
  background-color: rgba(247, 210, 0, 0.3);
  z-index: 3;
  color: #fff;
  padding: 10px;
}
.banner-button {
  background-color: #252525 !important;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}

.carousel-title {
  margin: 0;
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: 3;
  bottom: 15%;
  left: 0px;
}
.carousel-subtitle {
  margin: 0;
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: 3;
  bottom: 5%;
  left: 0px;
}

.carousel-custom-button {
  position: absolute;
  top: 25%;
}
.carousel-custom-button.prev {
  left: -60px;  
}
.carousel-custom-button.next {
  right: -60px;  
}
.home-service-carousel {
  margin: 20px 0 60px 0;
}
.home-service-carousel .carousel .slide {
  background: transparent;
}
.home-service-carousel .carousel .slide img {
  height: 140px;
  border-radius: 5px;
}
.home-service-carousel .carousel .slider-wrapper .slider .slide {
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
}

.no-link {
  color: inherit;
  text-decoration: none;
}

.text-ellipses {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.border-bottom-grey {
  border-bottom: 1px solid #ddd;
}

.flat-card {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0), 0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 3px 1px -2px rgba(0, 0, 0, 0) !important;
}
.flat-card:hover {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
}

a {
  outline: none;
}

.desc-wrapper .desc-head {}
.desc-wrapper .desc-bottom {margin: 24px;}
.desc-wrapper .desc-row {margin-bottom: 16px;}
.desc-wrapper .desc-main {font-size: 1.0rem; font-weight: bold;}
.desc-wrapper .desc-item {}

.qtyRow {
  margin-bottom: 40px;  
}
.qtyRow.first {
  margin-top: 30px;
}
.qtyRow.last {
  margin-bottom: 0;  
}
.qtyRow:hover .qtyIcon, .qtyIcon:hover {
  color: #444;
  background-color: #fff;
  transform: rotate(360deg);
  transition: all 0.3s ease-in-out 0s;
}
.qtyMainText:hover {
  color: #000;
  transform: rotate(360deg);
  transition: all 0.3s ease-in-out 0s;
}
.clients_main:hover .clients_icon, .clients_icon:hover {
  color: #444;
  background-color: #fff;
  transform: rotate(360deg);
  transition: all 0.3s ease-in-out 0s;
}
.footer-link-btn {
    color: #909090;
    background-color: transparent;
    border: 1px solid #909090;
    margin: 0 20px 0 0;
    text-decoration: none;
    padding: 8px 16px;
}
.footer-link-btn:hover {
  color: #fff;
  border: 1px solid #fff;
}
.clients_carousel .carousel .slide {
  margin-top: 5px;
}
.service_main_photo {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center;
}

.logo-subtitle {
  display: inline-block;
  vertical-align: middle;
  color: #9E854B;
  font-size: 15px;
  padding: 0 0 6px 6px;
  font-weight: bold
}

.leaflet-container {
  width: 100%;
  /* height: calc(100vh - 64px); */
  height: 100vh;
  margin-top: -64px;
}

/*** Media Css ***/

@media only screen and (max-width:1366px){
  .banner-top-title { top:18%; }
  .banner-top-subtitle2 {top:36%; }
  
}

@media only screen and (max-width:1190px){
  .logo-subtitle { display:none; }  
}

@media only screen and (max-width:1024px){
  .banner-top-title { top:12%;}  
  .banner-top-subtitle2 { top:36%; }
}

@media only screen and (max-device-width:812px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
  .banner-top-title {top:3%; }
  .banner-top-subtitle2 {top:32%; }
}

@media only screen and (max-width: 768px) {
  .banner-top-title {top:25px;}
  .banner-top-subtitle  {top:7px; }
  .banner-top-subtitle2 { top:36%;}
  .btn-menu-link { display: none !important; }
}

@media only screen and (max-device-width:736px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
  .banner-top-title {top:3%; }
  .banner-top-subtitle2 {top:32%; }

}

@media only screen and (max-device-width:667px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1){
  .banner-top-title {top:22%;}
  .banner-top-subtitle2 {top:56%;}

}

@media only screen and (max-width: 600px) {
  .leaflet-container {
    /* height: calc(100vh - 56px); */
    height: 100vh;
  }
}

@media only screen and (max-width: 480px) {
  .banner-top-title { top:0%;}
  .banner-top-subtitle2 { top:57%;}

}

@media only screen and (max-width:320px){
  .banner-top-title {top:0%;}
  .banner-top-subtitle { top:0px;}
  .banner-top-subtitle2 {top:54%;}
}

.razorpay-payment-button {
  margin: 8px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #F7D200;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 88px;
  box-sizing: border-box;
  min-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.4em;
  border-radius: 2px;
  text-transform: uppercase;

  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;

  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
}
.razorpay-payment-button:hover {
  background-color: rgb(172, 147, 0);
  text-decoration: none;
}