.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 52px;
  height: 18px;
  vertical-align: middle;
}
.lds-ellipsis span {
  position: absolute;
  top: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis span:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis span:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(3) {
  left: 22px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(4) {
  left: 38px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(15px, 0);
  }
}
.lds-text {
    display: inline !important; 
    /* font-size: 1.1rem !important;
    line-height: 2.4rem !important; */
    vertical-align: middle !important;
    margin-left: 5px !important;
    color: #000;
}